import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from "./Button";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useNavigate } from 'react-router-dom';
import styled, { css } from "styled-components/macro";
import axios from 'axios';
import { URL } from '../config.js'




const Intro = styled.div`
  margin-top: 8em;
  text-align: center;
`;

const btnCSS = css`
  margin-top: 2em;
`;

 
export default function SignUp () {

    const navigate = useNavigate();
    

 
    // States for registration
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [number ,setNumber] = useState('')
    const [numberError, setNumberError] = useState('')
    const [vibhagId, setVibhagId] = useState('');
    const [vibhagList, setVibhagList] = useState({})
    const [vibhagError, setVibhagError] = useState('');
    const [jillaId, setJillaId] = useState('')
    const [disable , setDisable] = useState(true)
    const [jillaList, setJillaList] = useState({})
    const [jillaError, setJillaError] = useState('');
    const [taluko, setTaluko] = useState('');
    const [talukaError, setTalukaError] = useState('');
    const [javabdari, setJavabdari] = useState('');
    const [javabdariError, setJavabdariError] = useState('')
    const [submitted, setSubmitted] = useState(false);

    
      const successMessage = () => {
        return (
            <div
                className="success"
                style={{
                    display: submitted ? '' : 'none',
                }}>
                {/* <h1>User {name} successfully registered!!</h1> */}
            </div>
        );
    };
 
    const handleName = (e) => {
        localStorage.setItem('userName', e.target.value)
        setName(e.target.value);
        setNameError("")
    };

    const handleVibhag = (id) => {
        const url = URL + '/admin/jilla/' + id 
        const data = {
           vibhag_id: id
        }
       
   axios.get(url , data).then((res) => {
    setDisable(false);
    setJillaList(res.data)
})
        setVibhagError("")  

        
    };
      const handleJillo = (id) => {
        setJillaId(id)
        setJillaError("")
    };
    
    const  handleTaluka = (e) => {
        setTaluko(e.target.value)
        setTalukaError("")
    }

     const  handleJavabdari = (e) => {
        setJavabdari(e.target.value)
        setJavabdariError("")
    }



    const handleNumber = (e) => {
        setNumber(e.target.value)
        setNumberError("")
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name === '' || name.length < 3) {
            setNameError('Please Enter Valid Name');
        } else if ( number.length < 10 || number.length > 10) {
            setNumberError('Please Enter Valid Number')
        } else if ( vibhagId === "") {
            setVibhagError('Please Select Vibhag')
        } else if (jillaId === "") {
            setJillaError('Please Select Jillo')
        } else if (taluko === "" || taluko.length < 3) {
            setTalukaError('Please Enter Valid Taluka Name')
        } else if (javabdari === "" || javabdari.length < 3 ) {
            setJavabdariError('Please Enter Your Valid  Javabdari')
        } 
         else {
            setSubmitted(true);
            setNameError('');
            setNumberError('');
            setVibhagError('')
            setJillaError('')
            setTalukaError("")
            setJavabdariError("")
            const url = URL + '/api/web-user-sign-up'

            const data = {
                name:name,
                mobile_number: number,
                jilla_id: jillaId,
                taluko:taluko,
                javabdari: javabdari
              }
              axios.post(url,data).then((res) => {
                localStorage.setItem('userId', res.data.id)
                    successMessage()
                  navigate('/quiz', {state:{id:'signupCompleted'}});
                })
        }


    };

    useEffect(()=> {
    const url = URL + '/admin/vibhag/all'

    const headerConfig = {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }
        
    axios.get(url).then((res) => {
        setVibhagList(res.data)
    })

},[])
 
   
 
    return (
        <Intro>
            <div className="sign-up">
                <div className="messages">
                <h1>પ્ર​વાસી કાર્યકર્તા મુલ્યાંકનમાં આપનુ સ્વાગત છે </h1>
                <h2 style={{marginBottom:'2rem'}}>Sign up Form</h2>
                    {successMessage()}
                </div>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Grid item xs>
                        <TextField
                            required
                            size='small'
                            type="text"
                            sx={{marginBottom: '10px', borderRadius:'10px', padding:'3px' }}
                            label="Name"
                            style={{ backgroundColor: 'white', display: "inline-block" }}
                            autoFocus
                            onChange={handleName}
                        />
                        </Grid>
                        <div  className='error'>
                            {nameError}
                        </div>
                        <Grid item xs>
                         <TextField
                         required
                            maxLength={10}
                            sx={{marginBottom: '10px', borderRadius:'10px', padding:'3px' }}
                            size='small'
                            type="Number"
                            inputProps={{ maxLength: 10 }}                             
                            label="Phone Number"
                            style={{ backgroundColor: 'white', display: "inline-block" }}
                            className="number-input"
                            onChange={handleNumber}
                        />
                        </Grid>
                        <div className='error'>
                            {numberError}
                        </div>
                        <Grid item xs style={{justifyContent:'center', display:'flex'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={vibhagList}
                                getOptionLabel={(option) => option.name || ""}
                                sx={{ width: 350 }}
                                renderInput={(params) => (
                                    <TextField
                                    required
                                    {...params}
                                    maxLength={10}
                                    sx={{ marginBottom: '10px', borderRadius: '10px', padding: '3px' }}
                                    size='small'
                                    type="text"
                                    label="Select Vibhag"
                                    />
                                    )}
                                 
                                     onChange={(event, value) => {
                                        setVibhagId(value.id)
                                        handleVibhag(value.id)
                                     }}
                            />
                        </Grid>
                         <div  className='error'>
                            {vibhagError}
                        </div>
                          <Grid item xs style={{justifyContent:'center', display:'flex'}}>
                             <Autocomplete
                             disabled={disable}
                                disablePortal
                                id="combo-box-demo"
                                options={jillaList}
                                getOptionLabel={(option) => option.name || ""}
                                sx={{ width: 350 }}
                                renderInput={(params) => (
                                    <TextField
                                    required
                                    {...params}
                                    maxLength={10}
                                    sx={{ marginBottom: '10px', borderRadius: '10px', padding: '3px' }}
                                    size='small'
                                    type="text"
                                    label="Select Jillo"
                                    />
                                    )}
                                      onChange={(event, value) => {
                                        handleJillo(value.id)
                                     }}
                                 
                            />
                        </Grid>
                         <div  className='error'>
                            {jillaError}
                        </div>
                        <Grid item xs>
                        <TextField
                        required
                            size='small'
                            type="text"
                            sx={{marginBottom: '10px', borderRadius:'10px', padding:'3px' }}
                            label="Taluka"
                            style={{ backgroundColor: 'white', display: "inline-block" }}
                            
                            onChange={handleTaluka}
                        />
                        </Grid>
                         <div  className='error'>
                            {talukaError}
                        </div>
                        <Grid item xs>
                        <TextField
                        required
                            size='small'
                            type="text"
                            sx={{marginBottom: '10px', borderRadius:'10px', padding:'3px' }}
                            label="Javabdari"
                            style={{ backgroundColor: 'white', display: "inline-block" }}
                            
                            onChange={handleJavabdari}
                        />
                        </Grid>
                         <div  className='error'>
                            {javabdariError}
                        </div>
                        
                        <Button type="submit"
                                fullWidth
                                variant="contained" style={{backgroundColor:'#DE651A', color:'white', marginTop:'3rem'}} className="btn">Sign Up</Button>
                    </Box>
                </div>
        </Intro>
    );
}